import { Game } from "@/models"
import GameJam from "./gameJam.vue";

export default {
  name: 'contentTemplate',
  components: {
    'game-jam': GameJam,
  },
  setup() {
    const ludumDares: Game[] = [
      {
        title: "Squirrel Mail",
        description: "Even squirrels need their mail! Play a squirrel mailman on his route to deliver a handful of letters. Use your colleagues to get across difficult terrain. Get to the mailbox to complete the level.",
        duration: "72 hours",
        date: "October 2024",
        teamSize: 1,
        event: "Ludum Dare 56",
        theme: "Tiny Creatures",
        pictureName: "squirrelMail.png",
        largePictureName: "squirrelMail.gif",
        pageUrl: "https://ldjam.com/events/ludum-dare/56/squirrel-mail",
      },
      {
        title: "Dreamlings Daycare",
        description: "Too many heroes have been summoning creatures to help them without a care for what happens to them afterwards. One creature got tired of it and built a daycare for abandoned summonings. Explore and find summonings abandoned in the wild and bring them back to the barns. Find resources and breed them up. You can use the mailbox to sell them for new heroes to use.",
        duration: "72 hours",
        date: "April 2024",
        teamSize: 5,
        event: "Ludum Dare 55",
        theme: "Summoning",
        pictureName: "daycare.png",
        largePictureName: "daycare.gif",
        pageUrl: "https://ldjam.com/events/ludum-dare/55/dreamlings-daycare",
      },
      {
        title: "Gladiato'R'us",
        description: "Our game is a baby gladiator arena. You start by going to the Baby Factory. You will then have to pick a baby every 10 seconds based on a short description. The babies that you don't pick will be fighting against you. Once your choices have been made, you are transported to the arena. You can then strategically place your babies and watch the battle unfold.",
        duration: "72 hours",
        date: "October 2022",
        teamSize: 4,
        event: "Ludum Dare 51",
        theme: "Every 10 seconds",
        pictureName: "gladiatorus.jpg",
        largePictureName: "gladiatorus.jpg",
        pageUrl: "https://ldjam.com/events/ludum-dare/51/gladiatorus",
      },
      {
        title: "Save Prisonner Ryan",
        description: "Your friend Ryan is on death row and you need to delay the execution as long as possible. They can't start the execution without Ryan receiving his last meal. It has already been ordered, but it has not arrived yet. Your job is to do everything in your power to make sure that the food doesn't reach the prison.",
        duration: "72 hours",
        date: "April 2022",
        teamSize: 3,
        event: "Ludum Dare 50",
        theme: "Delay the inevitable",
        pictureName: "ryan.jpg",
        largePictureName: "ryan.jpg",
        pageUrl: "https://redm4x.itch.io/save-prisoner-ryan",
      },
      {
        title: "Gift Stacker",
        description: "This game jam was spread over 5 days during christmas time so I only worked on it a few hours every evening. I worked on it alone and I gave myself the challenge to draw all my images. This is a simple stacking game. You need to stack as many gifts as you can in Santa's sleight without any going overboard. Santa's hats randomly spawn to help solidify the structure.",
        duration: "5 days",
        date: "December 2021",
        teamSize: 1,
        event: "Jame Gam: Christmas Edition",
        theme: "Save Christmas",
        object: "Stants Hat",
        pictureName: "giftStacker.png",
        largePictureName: "giftStacker.png",
        pageUrl: "https://boudichon.itch.io/jamegamchristmas",
      },
      {
        title: "Louis The Scientist",
        description: "Harambe has had his DNA tempered with and he is now unstable. You must experiment using various combinations of ingredients in order to prepare a cure. Be quick! He might not have much time left.",
        duration: "72 hours",
        date: "October 2021",
        teamSize: 4,
        event: "Ludum Dare 49",
        theme: "Unstable",
        pictureName: "louisthescientist.png",
        largePictureName: "louisthescientist.png",
        pageUrl: "https://redm4x.itch.io/louis-the-scientist",
      },
      {
        title: "Deep in the jungle",
        description: "Washed up on shore, you wake up on a deserted island (Or is it?). Fight, run or sneak past legions of cannibals in order to find shelter and survive. Deep in the jungle you will find your salvation!",
        duration: "72 hours",
        date: "April 2021",
        teamSize: 4,
        event: "Ludum Dare 48",
        theme: "Deeper and deeper",
        pictureName: "deepInJungle.png",
        largePictureName: "deepInJungle.png",
        pageUrl: "https://redm4x.itch.io/deep-in-the-jungle",
      },
      {
        title: "Looping Gazelle",
        description: "Looping gazelle is a puzzle game in which you need to navigate a maze and find the exit. To escape your endless torment you will need to find keys to open the door to your salvation. These keys are spread across the maze and puzzles, be careful not to get lost, one wrong turn and you might end up where you started!",
        duration: "72 hours",
        date: "October 2020",
        teamSize: 4,
        event: "Ludum Dare 47",
        theme: "Stuck in a loop",
        pictureName: "loopingGazelle.png",
        largePictureName: "loopingGazelle.png",
        pageUrl: "https://redm4x.itch.io/looping-gazelle",
      },
      {
        title: "Louis the Handyman",
        description: "Louis has been hired to repair a broken house. He needs to be quick to fix it though, the longer the damage stays, the more it will propagate. The controls are point & click.",
        duration: "42 hours",
        teamSize: 3,
        event: "Global Game Jam",
        theme: "Repair",
        date: "February 2020",
        pictureName: "louisthehandyman.png",
        largePictureName: "louisthehandyman.png",
        pageUrl: "https://globalgamejam2020.domcyr.com",
      },
      {
        title: "Epic Space Potato",
        description: "You have recently landed on a strange planet. You need to explore the planet and gather enough minerals to get home.",
        duration: "72 hours",
        date: "October 2019",
        teamSize: 4,
        event: "Ludum Dare 45",
        theme: "Start with nothing",
        pictureName: "epicSpacePotato.png",
        largePictureName: "epicSpacePotato.png",
        pageUrl: "https://redm4x.itch.io/epic-space-potato",
      },
    ]

    return { ludumDares };
  },
}