<template>
  <div id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand" href="#page-top"><img style="margin-right: 10px;padding-bottom: 2px;" width="40"
            src="../assets/img/logo.png" alt="..." />Dom Cyr</a>
        <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
          data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
          aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded" href="#gamejams">Game
                Jams</a></li>
            <!-- <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded"
                href="#squirrel">Squirrel</a></li> -->
          </ul>
        </div>
      </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead bg-primary text-white text-center">
      <div class="container d-flex align-items-center flex-column">
        <!-- Masthead Avatar Image-->
        <img class="masthead-avatar mb-5" src="../assets/img/logo.png" alt="..." />
        <!-- Masthead Heading-->
        <h1 class="masthead-heading text-uppercase mb-0">Dominic Cyr</h1>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Masthead Subheading-->
        <p class="masthead-subheading font-weight-light mb-0">Full Stack Developper - Avid Game Jam Participant</p>
      </div>
    </header>
    <!-- Portfolio Section-->
    <section class="page-section portfolio pb-3" id="gamejams">
      <div class="container">
        <!-- Portfolio Section Heading-->
        <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Game Jams</h2>
        <!-- Icon Divider-->
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <p class="mb-4">Game Jams are events in which you must create a video game in a specific timeframe (usually 72
          hours), while respecting a given theme.</p>
        <h3>My submissions:</h3>
        <!-- Portfolio Grid Items-->
        <div class="row justify-content-center">
          <game-jam v-for="(game, index) in ludumDares" :game="game" :key="index" key-prefix="ldGameJam"
            :key-unique="index" />
        </div>
      </div>
    </section>
    <!-- About Section-->
    <!-- <section class="page-section bg-primary text-white pb-5" id="squirrel">
      <div class="container">
        <h2 class="page-section-heading text-center text-uppercase text-white">Cute squirrel from my backyard</h2>
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <img class="img-fluid rounded" src="../assets/img/photography/Squirrel.jpg" />
      </div>
    </section> -->
  </div>
</template>
  
<script src="./contentTemplate.ts"></script>